<script setup>
const storeConfig = useStoreConfig()
const { wholesaleConfig } = storeToRefs(storeConfig)
</script>

<template>
  <template v-if="wholesaleConfig?.hide_prices">
    <p class="text-sm font-medium text-red-600 whitespace-break-spaces">
      {{getLocaleString(wholesaleConfig.value?.hide_price_description) ? getLocaleString(wholesaleConfig.value?.hide_price_description) : $t('listing.price-update-mode-description')}}
    </p>
  </template>
  <template v-else>
    <slot />
  </template>

</template>

<style scoped>

</style>